.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
}

.App-header {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


p {
  font-size: 12px;
  color: rgba(256, 256, 256, 0.8);
}

h2, h3 {
  font-size: 16px;
}

img {
  max-width: 100%;
}

.node {
  background-color: #282c34;
  border-radius: 20px;
  padding: 20px;
  margin: 20px;

}

a {
  color: #318bb2
}

